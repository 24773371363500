.mb {
    margin-bottom: 4px !important;
}

.mb-xs {
    margin-bottom: 8px !important;
}

.mr-xs {
    margin-right: 8px !important;
}

.mb-xs2 {
    margin-bottom: 12px !important;
}

.mb-s {
    margin-bottom: 16px !important;
}

.mb-sm {
    margin-bottom: 20px !important;
}

.mb-m {
    margin-bottom: 24px !important;
}

.mb-l {
    margin-bottom: 32px !important;
}

.mb-l2 {
    margin-bottom: 40px !important;
}

.mb-xl {
    margin-bottom: 56px !important;
}

.mb-xl2 {
    margin-bottom: 48px !important;
}

.mb-xxl {
    margin-bottom: 64px !important;
}

.mb-xxxl {
    margin-bottom: 72px !important;
}

.mb-76 {
    margin-bottom: 76px !important;
}

.mb-exl {
    margin-bottom: 80px !important;
}

.mb-exxl {
    margin-bottom: 96px !important;
}

.my-s {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
}

.my-m {
    margin-top: 16px;
    margin-bottom: 32px;
}

.mt-a {
    margin-top: auto;
}

.colorBrand {
    color: var(--primary-default) !important;
}

.colorFasting {
    color: var(--logo-color-fast) !important;
}

.colorError {
    color: var(--critical-default) !important;
}

.colorSuccess {
    color: var(--primary-default) !important;
}

.colorPromo {
    color: var(--decorative-safe) !important;
}

.colorTextSecondary {
    color: var(--on-surface-text-secondary-subdued) !important;
}

.centered {
    text-align: center !important;
}

.rightCentered {
    text-align: end !important;
}

.lineThrough {
    text-decoration: line-through;
}

.underline {
    text-decoration: underline;
}

.capitalize {
    text-transform: capitalize;
}

.colorMainSecondary {
    color: var(--text-main-secondary) !important;
}

.colorMain {
    color: var(--on-surface-text-main) !important;
}

.colorSuccessDefault {
    color: var(--success-default) !important;
}

.colorTextSecondarySubdued {
    color: var(--on-surface-text-secondary-subdued) !important;
}

.coloSafe {
    color: var(--decorative-safe) !important;
}

.flexGrow1 {
    flex-grow: 1;
}

.dFlex {
    display: flex;
}

.spaceBetween {
    justify-content: space-between;
}

.alignItemsStart {
    align-items: flex-start;
}

.flexShrink0 {
    flex-shrink: 0;
}

.bold {
    font-weight: 700;
}

.pRelative {
    position: relative;
}

.dFlexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexColumn {
    flex-direction: column;
}

.safe {
    color: var(--decorative-safe);
}

.textLeft {
    text-align: left;
}

.textPreLine {
    white-space: pre-line;
}
