.wrapper {
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.container {
    max-width: 528px;
    margin: 0 auto;
}

.parameters {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px;
    margin-top: -40px;
}

.parameters__item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 50%;
    height: 64px;
    padding: 12px;
    background: var(--surface-default);
    border: 1px solid var(--surface-default);
}

.parameters__item--level {
    border-right-color: var(--on-surface-border-subdued);
    border-bottom-color: var(--on-surface-border-subdued);
}

.parameters__item--age {
    border-bottom-color: var(--on-surface-border-subdued);
}

.parameters__item--weight {
    border-right-color: var(--on-surface-border-subdued);
}

.parameters__item div {
    flex-shrink: 0;
}

.parameters__content {
    max-width: calc(100% - 30px);
}

.parameters__item p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.image {
    margin: 0 auto;
}
