.card {
    width: 100%;
    max-width: calc(50% - 7.5px);
    position: relative;
    margin-bottom: 16px;
    background-color: transparent;
}

.card:before {
    content: '';
    position: absolute;
    bottom: 43px;
    left: 0;
    z-index: -4;
    width: 100%;
    height: 132px;
    background: linear-gradient(180deg, rgb(190 192 195), rgb(221 221 221));
    border: 1px solid var(--on-surface-border-subdued);
    box-sizing: border-box;
}

.buttonBox {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 14px 16px;
    background-color: var(--primary-default);
}

.white {
    background-color: var(--surface-default);
}

.imageWrapper {
    min-width: 164px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

@media screen and (min-width: 1024px) {
    .card {
        width: 164px;
    }
}
