.optionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.optionsWrapper--changedOrder label:first-child {
    order: 1;
}

.optionsWrapper--loading {
    position: relative;
}

.optionsWrapper__overlay {
    background: rgb(255 255 255 / 80%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    /* needed to override paypal built in huge z-index */
    z-index: 999;
    cursor: not-allowed;
}

.optionsWrapper__overlayLoader {
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    font-size: 50px;
}

.optionsWrapper__divider {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.optionsWrapper__dividerLine {
    flex-grow: 1;
    height: 1px;
    background-color: var(--border-default);
}

.optionsWrapper__dividerText {
    flex-shrink: 0;
}

.creditCardOption {
    position: relative;
}

/* credit card option: start */
.creditCardOption__container {
    position: relative;
    border: 1px solid var(--border-default);
    max-height: 56px;
    overflow: hidden;
    background: var(--surface-default);
}

.creditCardOption__input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
}

.creditCardOption__input:checked ~ .creditCardOption__container {
    border: 2px solid var(--on-surface-text-secondary-subdued);
    max-height: 100%;
}

.creditCardOption__toggler {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.creditCardOption__togglerTitle {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
}

.creditCardOption__togglerImages {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 16px;
    transition: 0.3s;
    gap: 4px;
}

.creditCardOption__togglerPaymentName {
    width: 70%;
    display: flex;
    align-items: center;
}

.creditCardOption__togglerPaymentName:before {
    content: '';
    position: relative;
    display: block;
    min-width: 20px;
    width: 20px;
    height: 20px;
    background-color: var(--surface-main);
    border-radius: 50%;
    border: 2px solid var(--primary-hovered);
    box-sizing: border-box;
    margin: 16px;
    box-shadow: inset 0 0 0 3px var(--surface-main);
    transition: 0.3s;
}

.creditCardOption__input:checked ~ * .creditCardOption__togglerPaymentName:before {
    background-color: var(--primary-hovered);
    box-shadow: inset 0 0 0 3px var(--surface-main);
}

.creditCardOption__body {
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 0 16px 16px;

    /* negative top margin needed to suppress form-container top padding that added by 3rd party lib */
    margin-top: -0.5em;
}

.creditCardOption__body iframe {
    width: 100%;
}

.creditCardOption__input:checked ~ * .creditCardOption__body {
    height: auto;
    opacity: 1;
}

.oneClickOption__list > * {
    margin-bottom: 12px;
}

.oneClickOption__list > *:last-child {
    margin-bottom: 0;
}

.oneClickOption__list--horizontal {
    display: flex;
    gap: 12px;
}

.oneClickOption__list--horizontal > * {
    margin-bottom: 0;
}

.googlePayBtn,
.applePayBtn {
    height: 54px;
    width: 100%;
}

.googlePayBtn button {
    border-radius: 0;
    outline: unset !important;
    background-size: 60px;
}

.payPalButton {
    border-radius: 0;
    max-width: unset;
}

.creditCardOption__bankCardBtn {
    overflow: hidden;
    width: 100%;
}

.paymentInactive {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.displayNone {
    display: none;
}

.payPalWrapper {
    width: 100%;
}

.payPalOrder {
    order: 1;
}

/* credit card option: end */

/* legal checkbox: start */
.legalCheckboxOverlay {
    pointer-events: none;
}

/* legal checkbox: end */
