.container {
    padding-bottom: 32px;
}

.containerMaxWidth {
    max-width: calc(560px + var(--default-indent-double));
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.slide {
    width: 295px !important;
    display: flex !important;
    flex-direction: column;
    background-color: var(--surface-default);
    height: 100%;
    margin-left: 8px;
    margin-right: 8px;
}

.slideImage {
    flex-shrink: 0;
}

.slideContent {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
    padding: 16px var(--default-indent);
}

.successStoriesDescription {
    margin-top: -64px;
}

@media (min-width: 1024px) {
    .container {
        padding-bottom: 0;
    }

    .successStoriesDescription {
        margin-top: 0;
    }
}
