.guaranteeContainer {
    padding: 24px var(--default-indent-double);
}

.container {
    max-width: calc(528px + var(--default-indent-double));
    margin: 0 auto;
    padding: 32px var(--default-indent);
}

.linkRefund {
    text-decoration: underline;
}

.checkoutWord {
    color: var(--decorative-safe);
}

.shieldIco {
    display: inline;
    position: relative;
    top: 3px;
    margin-left: 7px;
}

.imgBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imgItem {
    height: 24px !important;
}
