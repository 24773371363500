.methodsButtonWrapper {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
}

.firstMethod label:first-child {
    order: 1;
}

.wrapper {
    position: relative;
    border: 1px solid var(--border-default);
    border-radius: 12px;
    max-height: 56px;
    overflow: hidden;
    background: var(--surface-default);
}

.labelWrapper {
    position: relative;
    margin-bottom: 8px;
}

.input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
}

.input:checked ~ .wrapper {
    border: 2px solid var(--on-surface-text-secondary-subdued);
    max-height: 100%;
}

.paymentOptionButton {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.titleWrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
}

.input:checked ~ * .titleWrapper {
    margin-bottom: 16px;
}

.input:checked ~ * .titleWrapper:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    border: 1px solid var(--border-default);
}

.imgWrapper {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 16px;
    filter: saturate(0%);
    transition: 0.3s;
    gap: 4px;
}

.input:checked ~ * .imgWrapper {
    filter: none;
}

.paymentName {
    width: 70%;
    display: flex;
    align-items: center;
}

.paymentName:before {
    content: '';
    position: relative;
    display: block;
    min-width: 20px;
    width: 20px;
    height: 20px;
    background-color: var(--surface-main);
    border-radius: 50%;
    border: 2px solid var(--primary-hovered);
    box-sizing: border-box;
    margin: 16px;
    box-shadow: inset 0 0 0 3px var(--surface-main);
    transition: 0.3s;
}

.input:checked ~ * .paymentName:before {
    background-color: var(--primary-hovered);
    box-shadow: inset 0 0 0 3px var(--surface-main);
}

.payments {
    opacity: 0;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.input:checked ~ * .payments {
    height: auto;
    opacity: 1;
}

.googlePayBtn,
.applePayBtn {
    height: 54px;
    width: 100%;
    margin-bottom: 16px;
}

.googlePayBtn button {
    border-radius: 8px;
}

.bankCard {
    margin-bottom: 16px;
}

.bankCardBtn {
    border-radius: 8px;
    overflow: hidden;
}

.paymentInactive,
.payments {
    padding-bottom: 10px;
}

.paymentInactive,
.paymentWrapper *[id*='solid-payment-form-container'] {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.wrapperOneClickPayment {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0 16px;
}

.wrapperOneClickPayment > :last-child {
    margin-bottom: 0;
}

.payPalWrapper {
    width: 100%;
}

.payPalOrder {
    order: 1;
}

.legalCheckboxOverlay {
    pointer-events: none;
}

.oneClickButtonsWrapper {
    margin: 0 auto;
    max-width: 325px;
    width: 100%;
}
