.optionBoxDes {
    width: 100%;
    position: relative;
}

.options {
    padding-bottom: 12px;
}

.btnFixed {
    position: fixed !important;
    max-width: 400px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    padding: 0 16px 16px;
    z-index: 10000;
}

.btnFixed button {
    box-shadow: 0 27px 13px 47px var(--surface-main);
}

@media screen and (min-width: 768px) {
    .btnFixed {
        max-width: calc(576px + var(--default-indent));
    }
}
