.container {
    max-width: calc(560px + var(--default-indent-double));
    margin: 0 auto;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
}

.list {
    margin-bottom: 24px;
}

.listItem {
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: var(--surface-default);
    margin-bottom: 12px;
    padding: 12px 16px;
}

.listItem__icon {
    padding: 16px;
    flex-shrink: 0;
    background-color: var(--surface-main);
}

.listItemNumber {
    font-weight: 500;
}
