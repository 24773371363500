.container {
    background-color: var(--surface-default);
}

.ratingWrapper {
    max-width: 432px;
    margin: 0 auto;
    padding: 56px 16px;
}

.imageWrapper {
    position: relative;
    margin-bottom: 20px;
}

.yogaAppText {
    position: absolute;
    left: 0;
    right: 0;
    top: 67%;
}
