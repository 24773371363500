.wrapper {
    padding: 40px 16px 0;
    background: linear-gradient(180deg, rgb(184 250 202) 0%, rgb(197 231 204) 88%, rgb(222 247 225) 100%);
}

.container {
    max-width: 400px;
    margin: 0 auto;
}

.parameters {
    position: relative;
    height: 228px;
}

.parameters__item {
    position: absolute;
    min-width: 96px;
    max-width: 132px;
    max-height: 96px;
}

.parameters__item:after {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100px;
    border-top: 1px dashed var(--decorative-b);
}

.parameters__content {
    position: relative;
    z-index: 2;
    padding: 10px 19px;
    background: var(--surface-default);
    border-radius: 100px;
}

.parameters__item p {
    /* stylelint-disable-next-line */
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.parameters__item--type {
    top: 16px;
    left: 4px;
}

.parameters__item--type:after {
    bottom: 10px;
    right: -59px;
    transform: rotate(38deg);
}

.parameters__item--goal {
    top: 0;
    right: 0;
}

.parameters__item--goal:after {
    bottom: 14px;
    left: -44px;
    transform: rotate(-43deg);
}

.parameters__item--weight {
    right: 2px;
    bottom: 31px;
}

.parameters__item--weight:after {
    top: 10px;
    left: -60px;
    transform: rotate(25deg);
}

.parameters__item--date {
    left: 4px;
    bottom: 47px;
}

.parameters__item--date:after {
    top: 35%;
    right: -80%;
    transform: rotate(-10deg);
}

.parameters__image {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
