.planContainer {
    margin-bottom: 24px;
}

.planItem {
    position: relative;
    margin-bottom: 12px;
    display: block;
}

.planInput {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
}

.planBox {
    display: flex;
    border-color: var(--option-border-color);
    border-style: solid;
    transition: 0.2s linear;
}

.planLeft {
    width: 100%;
    padding: 16px 13px;
    border-width: 2px 0 2px 2px;
    border-style: inherit;
    border-color: inherit;
    border-radius: 12px 0 0 12px;
}

.planRight {
    width: 100%;
    max-width: 90px;
    padding: 9px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 0 12px 12px 0;
    border-style: solid;
    border-color: inherit;
}

.planPrice {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: var(--on-surface-text-main);
}

.planPerWeek {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: var(--on-surface-text-secondary-subdued);
}

.planItem:not(.recurrent) .planInputChecked ~ .planBox {
    border-color: var(--primary-hovered);
}

.planItem:not(.recurrent) .planInputChecked ~ .planBox .planRight {
    background-color: var(--decorative-safe);
}

.planItem:not(.recurrent) .planInputChecked ~ .planBox .planPrice,
.planItem:not(.recurrent) .planInputChecked ~ .planBox .planPerWeek {
    color: var(--surface-default);
}

.planItem:not(.recurrent) .planInputChecked .planRight {
    background-color: var(--decorative-safe);
}

.planOldPrice {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: rgb(134 150 166 / 60%);
    position: relative;
}

.planOldPrice:after {
    content: '';
    position: absolute;
    width: 35px;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-22deg);
    background-color: var(--on-surface-text-main);
}

.planItem:not(.recurrent) .planInputChecked + .planBox .planOldPrice {
    color: var(--surface-default);
    opacity: 0.6;
}

.planItem:not(.recurrent) .planInputChecked + .planBox .planOldPrice:after {
    background-color: var(--surface-default);
}

.planItem:nth-child(2) .planOldPrice:after,
.recurrent .planOldPrice:after {
    background-color: var(--decorative-safe);
}

.planSave,
.planSavePromo {
    position: absolute;
    top: 0;
    left: 15px;
    transform: translateY(-50%);
    background-color: var(--decorative-safe);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--surface-default);
    padding: 0 15px;
    border-radius: 100px;
}

.planHint {
    white-space: pre;
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    overflow: hidden;
    padding: 0 8px;
    background-color: var(--hint-bg);
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: var(--on-surface-text-main);
    opacity: 0;
    border-radius: 12px;
    transition: 0.1s linear;
}

.planItem:not(.recurrent) .planInputChecked ~ .planHint {
    opacity: 0.8;
}

.planItem:not(:first-child, .recurrent) .planInputChecked ~ .planBox {
    margin-bottom: 38px;
}

.oldPrice {
    text-decoration: line-through;
    margin-right: 4px;
}

.planSavePromo {
    background-color: var(--decorative-safe);
    color: var(--surface-main);
}

.onePricePlanContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.onePricePlanContainerEnd {
    align-items: flex-end;
    margin-bottom: 32px;
}

.onePricePlanHeaderSmall {
    font-size: 24px;
}

.leftOnePrice {
    font-size: 24px;
    line-height: 32px;
    max-width: 50%;
}

.leftOnePriceContainer h1 {
    font-size: 24px;
    line-height: 32px;
}

.rightOnePrice {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.specialOfferHint {
    width: fit-content;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    background: var(--decorative-safe);
    border-radius: 100px;
    padding: 2px 10px;
    color: var(--surface-default);
    margin-bottom: var(--default-indent);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    display: flex;
}

.onePrice {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;
}

.price {
    padding: 4px 6px;
    border-radius: 8px;
    color: var(--surface-main);
    background-color: var(--decorative-safe);
}

.oldOnePrice {
    position: relative;
    margin-right: 10px;
}

.oldOnePrice:after {
    content: '';
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    bottom: 25%;
    left: 0;
    background-color: var(--on-surface-text-main);
    transform: rotate(-15deg);
    transform-origin: left;
}

.greyHint {
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 8px;
}

.greyHintOpacity {
    color: #d4dae0;
}

.colorBrand {
    color: var(--decorative-safe);
}

.second {
    margin-top: 32px;
}

.second .planLeft {
    border-radius: 0 0 0 12px;
    border-width: 0 0 2px 2px;
}

.second .planRight {
    border-width: 0 2px 2px 0;
    border-radius: 0 0 12px;
}

.mostPopular {
    position: absolute;
    width: 100%;
    transform: translate(0, -100%);
    text-align: center;
    background-color: var(--primary-hovered);
    color: var(--surface-default);
    padding: 2px 0;
    border-radius: 10px 10px 0 0;
    text-transform: uppercase;
}

.recurrent .planSave,
.recurrent .leftPartCaption {
    display: none;
}

.recurrent .planRight {
    max-width: initial;
    background-color: transparent !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-left-width: 0;
    padding-right: 7px;
}

.recurrent .planPrice {
    display: flex;
    line-height: initial;
    align-items: flex-start;
    position: relative;
    padding-right: 16px;
}

.priceBigPart svg {
    position: absolute;
    z-index: -1;
    right: 0;
}

.priceBigPart {
    font-weight: 900;
    font-size: 42px;
    margin-top: -5px;
}

.recurrent .planPerWeek {
    position: absolute;
    left: 37px;
    bottom: 5px;
    margin: 17px 0 0;
    font-size: 8px;
    line-height: 18px;
    font-weight: normal;
    color: var(--on-surface-text-main);
}

.recurrent .planOldPrice {
    margin: auto 25px 0 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
}

.recurrent .planOldPrice:after {
    transform: none;
    width: 110%;
    left: -6%;
}

.recurrent .planBox {
    border-color: var(--primary-hovered);
    filter: grayscale(100);
    min-height: 74px;
    height: auto;
    background-color: var(--surface-default);
    border-radius: 10px;
}

.recurrent .planLeft,
.recurrent .planRight {
    opacity: 0.5;
}

.recurrent .planLeftPlanName {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.recurrent .planLeftPlanName:before {
    content: '';
    position: relative;
    display: block;
    min-width: 20px;
    width: 20px;
    height: 20px;
    background-color: var(--surface-main);
    border-radius: 50%;
    border: 2px solid var(--primary-hovered);
    box-sizing: border-box;
    margin-right: 10px;
    box-shadow: inset 0 0 0 3px var(--surface-main);
    transition: 0.3s;
}

.recurrent .planInputChecked ~ .planBox {
    filter: grayscale(0);
}

.recurrent .planInputChecked ~ .planBox .planLeft,
.recurrent .planInputChecked ~ .planBox .planRight {
    opacity: 1;
}

.recurrent .planInputChecked ~ .planBox .planLeftPlanName:before {
    background-color: var(--primary-hovered);
    box-shadow: inset 0 0 0 3px var(--surface-main);
}
