.payPalBtnWrapperDefault {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.payPalBtnWrapper {
    margin: 0 auto;
}

.hint {
    margin: 16px 0 20px;
}

.payPal {
    width: 100%;
    max-width: 325px;
    min-height: 54px;
    margin: 0 auto;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffc439;
    cursor: pointer;
}

.payPalImage {
    height: 25px !important;
}

.payPalBtnMarginBottom {
    margin-bottom: 16px;
}

.payPal.disabled {
    pointer-events: none;
}
