.redesignedFont {
    font-family: Lora, serif !important;
    font-optical-sizing: auto;
    font-weight: 500 !important;
    font-style: normal;
    letter-spacing: -0.2px;
}

.highlightedTextColor {
    /* This color is experimental and doesn't exist in components lib */
    color: #00986e !important;
}

.capitalise {
    text-transform: capitalize;
}
