.root {
    width: 100%;
    padding: 6px var(--default-indent);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--decorative-a);
    z-index: 100;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: var(--decorative-c);
    transition: 0.3s;
}

.rounded {
    border-radius: 8px;
    width: calc(100% - 20px);
    margin: 0 auto 24px;
}

.timerBox {
    font-size: 16px;
    line-height: 24px;
}

.timerEnded {
    background: #8696a6;
}

.echoTitle {
    font-size: 16px;
    line-height: 24px;
}

.icon {
    margin-right: 8px;
}
