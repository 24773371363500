.wrapper {
    background-color: var(--surface-default);
}

.container {
    max-width: calc(560px + var(--default-indent-double));
    margin: 0 auto;
    padding: 56px var(--default-indent);
}

.list {
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px 24px;
}

.listItem {
    flex-basis: calc(50% - 24px);
    flex-grow: 1;
    flex-shrink: 0;
}

.listItemNumber {
    display: block;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.2px;
    margin-bottom: 8px;
    font-weight: 400 !important;
    color: var(--on-surface-text-main);
}

@media screen and (min-width: 768px) {
    .list {
        column-gap: 40px;
    }
}
