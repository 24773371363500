.priceCard {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    cursor: pointer;
}

.priceCardBodyWrapper {
    background-color: var(--surface-default);
    box-shadow: inset 0 0 0 1px var(--border-default), 0 4px 8px rgb(0 0 0 / 0%);
    border-radius: 8px;
    cursor: pointer;
}

.priceCardBodyWrapperActive {
    box-shadow: inset 0 0 0 2px var(--primary-hovered), 0 4px 8px rgb(0 0 0 / 8%);
}

.priceCardBodyWrapperWithLabel {
    border-radius: 12px 12px 8px 8px;
}

.labelAboveCard {
    padding-left: 8px;
}

.priceCardLabel {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 2px 10px;
    background-color: var(--primary-hovered);
}

.priceCardBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.priceCardBody * {
    transition: 0.3s;
}

.priceCardLeftCol,
.priceCardRightCol {
    display: flex;
}

.priceCardLeftCol {
    align-items: center;
}

.priceCardRightCol {
    align-items: end;
}

.priceCardTitle {
    margin-left: 10px;
}

.priceCardPeriod {
    width: fit-content;
}

.priceCardPeriodText {
    font-size: 10px !important;
    line-height: 16px !important;
    letter-spacing: 1px;
}

.priceCardFullPrices {
    display: flex;
    gap: 6px;
    padding-top: 4px;
}

.priceCardInput {
    width: 0;
    height: 0;
    position: absolute;
}
