.sliderWrapper {
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 80px;
}

.image {
    width: auto;
    height: 100%;
    max-height: 406px;
    margin: 0 auto;
}
