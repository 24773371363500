.wrapper {
    padding: 40px 16px 32px;
    position: relative;
    background: linear-gradient(180deg, rgb(184 250 202) 0%, rgb(197 231 204) 88%, rgb(222 247 225) 100%);
}

.container {
    max-width: 400px;
    margin: 0 auto;
}

.goals {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 32px;
}

.goals__item {
    padding: 4px 12px;
    background: var(--surface-default);
    border-radius: 0 10px;
}

.parameters {
    position: relative;
    width: 100%;
    height: 168px;
}

.parameters:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 247px;
    height: 107px;
    border: 1px dashed var(--decorative-b);
    border-radius: 50%;
}

.parameters__item {
    position: absolute;
    z-index: 2;
    min-width: 96px;
    max-width: 144px;
    padding: 10px 19px;
    background: var(--surface-default);
    border-radius: 100px;
}

.parameters__item p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.parameters__item--level {
    top: 0;
    left: 46%;
    transform: translateX(-50%);
}

.parameters__item--weight {
    right: 0;
    top: 43%;
    transform: translateY(-50%);
    max-width: 112px;
}

.parameters__item--type {
    bottom: 0;
    left: 52%;
    transform: translateX(-50%);
}

.parameters__item--age {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 112px;
}
