.container {
    padding: 56px 16px;
    background-color: var(--surface-default);
}

.wrapper {
    max-width: 400px;
    margin: 0 auto;
}

.ratings {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 40px;
    padding: 0 5px;
}

.ratings__item {
    position: relative;
    width: 160px;
    display: flex;
    justify-content: space-between;
}

.ratings__item svg {
    flex-shrink: 0;
}

.ratings__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ratings__text {
    margin-top: 3px;
}
