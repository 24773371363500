.container {
    display: flex;
    gap: 16px;
    justify-content: center;
    padding: 0 var(--default-indent);
}

.ratingsBannerStoresInfo {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
}
