.guideCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--success-surface);
    box-shadow: inset 0 0 0 1px var(--border-default), 0 4px 8px rgb(0 0 0 / 0%);
    border-radius: 8px;
}

.guideCardLeft {
    display: flex;
    align-items: center;
}

.guideCardRight {
    display: flex;
    align-items: end;
}

.lineThrough {
    text-decoration: line-through;
    white-space: nowrap;
}

.guideCardImg {
    margin-right: 6px;
    flex-shrink: 0;
}

.guideCardInfo {
    display: flex;
    padding-top: 24px;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
}

.guideCardTitle {
    padding: 2px 4px;
    background: var(--primary-default);
    margin-bottom: 4px;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
}
