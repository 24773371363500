.container {
    display: flex;
    align-items: start;
    border-radius: 0 8px 8px 0;
    filter: grayscale(1);
    margin-left: 18px;
    position: relative;
    padding-right: 7px;
}

.icon {
    position: absolute;
    top: 0;
    transform: translate(-100%, 0);
}

.isActive {
    filter: grayscale(0);
}

.smallPrices {
    position: relative;
    top: 5px;
}

.rightPart {
    margin-left: 2px;
}

.periodLabel {
    white-space: nowrap;

    /*@ToDo: remove after design refactor*/
    font-weight: 400 !important;
    font-size: 8px !important;
    line-height: 10px !important;
}
