.storesList {
    display: flex;
    gap: 18px;
}

.storesList__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    padding: 4px;
}

.ratingWrapper {
    display: flex;
    align-items: center;
}

.starsWrapper {
    width: 104px;
    height: 19px;
}

.starsWrapper svg {
    width: 100%;
    height: 100%;
}

.starsWrapperSmall {
    width: 88px;
    height: 16px;
}

.appInfo {
    display: flex;
    margin-bottom: 6px;
}

.appInfo__divider {
    background-color: var(--border-default);
    margin: 0 8px;
    width: 1px;
}
