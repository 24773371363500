.wrapperToggle {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 31px;
}

.checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.checkboxView {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #bfbcbc;
    transition: 0.3s;
    border-radius: 30px;
}

.checkboxView:before {
    position: absolute;
    content: '';
    height: 28px;
    width: 28px;
    left: 2px;
    bottom: 1.5px;
    background-color: var(--surface-default);
    border-radius: 50%;
    transition: 0.3s;
}

.checkbox:checked + .checkboxView {
    background-color: var(--primary-default);
}

.checkbox:checked + .checkboxView:before {
    transform: translateX(19px);
    box-shadow: 0 3px 1px 0 rgb(0 0 0 / 10%), 0 1px 1px 0 rgb(0 0 0 / 16%), 0 3px 8px 0 rgb(0 0 0 / 15%);
}
