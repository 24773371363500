.wrapper {
    width: 100%;
    background: var(--surface-default);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 32px;
}

.title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
}

.priceWrapper {
    padding: 4px 12px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--border-default);
    display: flex;
    justify-content: space-between;
}

.priceWrapperActive {
    border-color: var(--primary-hovered);
}

.lineTrough {
    text-decoration: line-through;
}

.priceTextWrapper {
    display: flex;
    align-items: center;
}

.tadaImg {
    margin-left: 4px;
}
