.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    min-height: 200px;
    margin: 0 10px 12px;
    max-width: 400px;
    background: var(--surface-default);
}

.commentText {
    margin: 12px 0;
}
