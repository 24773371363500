.container {
    max-width: 400px;
    padding-left: var(--default-indent);
    padding-right: var(--default-indent);
    margin: 0 auto 56px;
}

.userInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.redesignedPriceCard {
    border-radius: 0 !important;
}

.userInfoItem {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 50%;
    padding: 0 23px;
}

.userInfoItem:first-of-type {
    border-right: 1px solid var(--border-default);
    align-items: flex-end;
}

.userInfoData {
    display: flex;
}

.userInfoIcon {
    margin-right: 8px;
}

.timerContainer {
    margin-bottom: 24px;
    min-height: 40px;
    background-color: var(--on-surface-text-on-primary);
    font-weight: 700;
}

.productsBlockWrapper {
    max-width: 400px;
    margin: 0 auto;
}

.pricesWrapper {
    margin-bottom: 24px;
}

.guaranteeLink {
    text-decoration: underline;
}
