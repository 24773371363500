.priceTag {
    align-items: start;
    border-radius: 0 8px 8px 0;
    filter: grayscale(1);
    margin-left: 18px;
    position: relative;
    padding-right: 4px;
    max-height: 60px;
}

.priceTagActive {
    filter: grayscale(0);
}

.priceTagPike {
    position: absolute;
    top: 0;
    transform: translate(-100%, 0);
}

.priceTagCost {
    display: flex;
    gap: 2px;
    margin-top: -4px;
}

.priceTagCostRightCol {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
}

.priceTagPeriod {
    white-space: pre;
}

.priceTagCents {
    height: 20px;
}
