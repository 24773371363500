.container {
    background-color: var(--surface-default);
}

.wrapper {
    display: flex;
    flex-direction: column;
    padding: 56px 16px;
    gap: 16px;
    align-items: center;
    max-width: 432px;
    margin: 0 auto;
}

.ratingsBannerStoresInfo {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
}
