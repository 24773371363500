.container {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-width: 2px !important;
    flex-shrink: 0;
}

.container > * {
    border-radius: 50%;
    position: absolute;
    width: 12px;
    height: 12px;
}
