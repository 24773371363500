.priceTag {
    border-radius: 0 8px 8px 0;
    filter: grayscale(1);
    margin-left: 18px;
    position: relative;
    padding-right: 8px;
    height: 48px;
}

.priceTagPike {
    position: absolute;
    top: 0;
    transform: translate(-100%, 0);
}

.priceTagPeriod {
    white-space: pre;
    margin-top: -6px;
}

.priceTagCost {
    display: flex;
    flex-direction: column;
    align-items: end;
}
